.notification {
  position: fixed;
  left: 50%;
  z-index: 9999999;
  text-align: center;
}

.container {
  width: 100%;
  height: 100%;
}