.container {
  .mt20 {
    margin-top: 20px;
  }
  .content {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    .title {
      font-size: 32px;
      line-height: 43px;
      color: #242424;
      margin-bottom: 40px;
      font-weight: bold;
    }
    .subTitle {
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      color: #242424;
    }
    .mt40 {
      margin-top: 40px;
    }
    .text {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.6);
    }
    .row {
      display: flex;
      align-items: flex-start;
    }
    .left {
      flex: 1;
      margin-right: 60px;
    }
    .right {
      width: 240px;
      background: #FFFFFF;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      overflow: hidden;
      .imageWrapper {
        width: 240px;
        height: 220px;
        margin-bottom: 10px;
        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .name {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #242424;
        margin-bottom: 10px;
      }
      .row {
        display: flex;
        align-items: center;
      }
      .avatar {
        width: 20px;
        height: 20px;
        object-fit: cover;
        margin-right: 10px;
      }
      .owner {
        font-size: 14px;
        line-height: 28px;
        color: #767676;
        margin-bottom: 0;
      }
      .cardContent {
        padding: 10px 10px 20px 10px;
      }
    }
    .col {
      flex: 1;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
