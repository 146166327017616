.container{ 
  max-width: 620px;
  width: 100%;
  margin: 0 auto;
  .row {
    display: flex;
    align-items: flex-start;
    .col {
      flex: 1;
      margin-right: 25px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .mt54 {
    margin-top: 54px;
  }

  .subTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 12px;
    margin-top: 52px;
  }

  .btnBack {
    width: 42px;
    height: 42px;
    border-radius: 8;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background-color: #C1C4D0;
    }
    .arrowLeft {
      width: 18px;
    }
    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
      .arrowLeft {
        width: 18px;
      }
    }
  }

  .campaignName {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 0;
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  .mb27 {
    margin-bottom: 27px;
  }
}
