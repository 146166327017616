.container {
  background-color: #ffffff;
  .sideBar {
    height: calc(100vh);
    overflow-y: auto;
    position: fixed;
    left: 0;
    top: 0;
    width: 281px;
    z-index: 102;
    box-shadow: 9px 9px 20px rgba(0, 0, 0, 0.09);
    border-radius: 0 14px 14px 0;
  }
  .sideBarCollapseWrapper {
    width: 80px;
    &:hover {
      width: 281px;
    }
  }
  .backdrop {
    z-index: 100;
  }
  .header {
    position: fixed;
    top: 0;
    z-index: 100;
    background: #f7f9fb;
    left: 0;
    right: 0;
    @media (max-width: 1024px) {
      width: 100%;
      transform: translate3d(0, 0, 0) !important;
    }
  }
  .headerCollapse {
    left: 80px
  }
  .content {
    padding-top: 0px;
    .main {
      // padding-left: 281px;
      min-height: calc(100vh);
      padding: 90px 40px 30px 40px;
      overflow: hidden;
      @media (max-width: 1024px) {
        width: 100% !important;
        transform: translate3d(0, 0px, 0px) !important;
      }
    }
    .showMenu {
      width: calc(100% - 0);
      transform: translate3d(0, 0px, 0px);
      transition: all 0.5s ease 0s;
    }
    .showMenuCollapse {
      width: calc(100% - 80px);
      transform: translate3d(80px, 0px, 0px) !important;
      transition: all 0.5s ease 0s;
    }
    .noTransition {
      transition: none !important;
    }
    @media (max-width: 767px) {
      .main {
        padding-left: 0;
        padding: 80px 0 40px 0;
      }
    }
   
  }

  @media (max-width: 767px) {
    .header {
      left: 0;
    }
    .sideBar {
      transform: translateX(-100%);;
    }
    .showSideBar {
      transform: translateX(0);
    }
  }

  .sideBarMobile {
    display: block;
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
  .sideBarShow {
    @media(max-width: 1024px) {
      z-index: 101;
    }
  }
}
