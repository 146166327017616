.input {
  box-shadow: none !important;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #BFC7CA;
  background-color: #FAFAFC;
  font-size: 16px;
  color: #585858;
  font-weight: 600;
  outline: 0;
  padding: 0 15px;
  width: 100%;
  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus {
    background-color: transparent !important;
  }
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #6e7580;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: #6e7580;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: #6e7580;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: #6e7580;
  }
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover, 
.input:-webkit-autofill:focus{
  border: solid 1px #BFC7CA;
  -webkit-text-fill-color: #092631;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


.h50 {
  height: 50px;
  padding: 0 15px 0 25px;
}


.btnEye {
  position: absolute;
  right: 0;
  top: 50% !important;
  transform: translateY(-50%) !important;
  margin-right: 16px;
  cursor: pointer;
  .eyeIcon {
    width: 20px;
    color: #999999;
  }
}

.note {
  font-size: 13px;
  color: #666666;
  margin-top: 10px;
  margin-bottom: 0
}

.focus {
  border: 1.5px solid #EBDAAD !important;
  box-shadow: 0px 2px 12px rgba(255, 184, 0, 0.2) !important;
  background-color: #ffffff;
}

.errorField {
  border-color: #ff6565 !important
}

.fullBorder {
  border-radius: 4px;
  box-shadow: inset 0 1px 2px 0 rgba(102, 113, 123, 0.21) !important;
  padding: 0 16px;
  border: 1px solid #e2e5ed;
}

.floatingLabel {
  position: absolute;
  bottom: 8px;
  left: 0;
  color: #999999;
  pointer-events: none;
  // transition: all 0.5s ease-in-out;
  transition: .5s;
  font-size: 12px;
}

.inputContainer {
  position: relative;
  display: inline-flex;
  width: 100%
}

.labelError {
  font-size: 12px;
  color: #CF1E1E !important;
}

.errorMessage {
  font-size: 12px;
  color: #CF1E1E !important;
  margin-left: 6px
}

.disabled {
  background-color: #efefef !important;
}

.inputWrapper {
  display: flex;
  align-items: center;
  .inputContainer {
    flex: 1
  }
}


.remainCharactor {
  position: absolute;
  right: 0;
  bottom: -40px;
  font-size: 14px;
  color: #666666;
}
