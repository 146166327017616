.btn {
  background-color: transparent;
  &:hover, &:active, &:visited, &:focus {
    outline: 0;
  }
}

.btn {
  position: relative;
  display: flex;
  .loader {
    margin-left: 6px;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.eventNone {
  pointer-events: none;
}
