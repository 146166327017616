.input {
  box-shadow: none !important;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #BFC7CA;
  background-color: #FAFAFC;
  font-size: 16px;
  color: #585858;
  font-weight: 600;
  outline: 0;
  width: 100%;
  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus {
    background-color: transparent !important;
  }
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #6e7580;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: #6e7580;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: #6e7580;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: #6e7580;
  }
}

.h50 {
  height: 50px;
}

.bb1 {
  border: 0;
  border-bottom: solid 1px #BFC7CA;
  border-radius: 0;
}

.hasError {
  
}

.focus {
  border: 1.5px solid #EBDAAD !important;
  box-shadow: 0px 2px 12px rgba(255, 184, 0, 0.2) !important;
  background-color: #ffffff;
}

.errorField {
  border-color: #ff6565 !important
}

.fullBorder {
  border-radius: 4px;
  box-shadow: inset 0 1px 2px 0 rgba(102, 113, 123, 0.21) !important;
  padding: 0 16px;
  border: 1px solid #e2e5ed;
}

.floatingLabel {
  position: absolute;
  bottom: 8px;
  left: 0;
  color: #999999;
  pointer-events: none;
  // transition: all 0.5s ease-in-out;
  transition: .5s;
  font-size: 12px;
}

.inputContainer {
  position: relative;
  display: inline-flex;
  width: 100%
}

.labelError {
  font-size: 12px;
  color: #CF1E1E !important;
}

.errorMessage {
  font-size: 12px;
  color: #CF1E1E !important;
  margin-left: 6px
}

.icon {
  width: 14px;
  transition: all 0.2s;
}

.iconUp {
  transform: rotate(180deg);
}
