.container {
  box-shadow: 0px 1px 10px rgba(122, 122, 122, 0.1);
  border-radius: 4px;
  border: 1px solid #DDE3F2;
  margin-bottom: 20px;
  padding: 16px 12px 20px 12px;
  .imageWrapper {
    margin-bottom: 10px;
    background: #FFFFFF;
    box-shadow: 0px 10px 10px -10px rgba(0, 39, 85, 0.5), 0px 6px 20px rgba(0, 39, 85, 0.35);
    border-radius: 8px;
    margin-bottom: 16px;
  }
  .image {
    width: 100%;
    height: 220px;
    object-fit: cover;
    border-radius: 8px;
  }

  .rowBetween {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .itemName {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #42496E;
    margin-bottom: 0;
  }
  .items {
    background: #F4F5F7;
    border: 1px solid #DFE0E6;
    border-radius: 18px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 16px;
    color: #5E6484;
    padding: 0 10px;
    white-space: nowrap;
  }
  .rare {
    width: 20px;
  }
  .amount {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 19px;
    color: #949496;
  }
  .nftType {
    background: #ffffff;
    border: 1px solid #DFE0E6;
    border-radius: 18px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 16px;
    color: #5E6484;
    padding: 0 10px;
    margin-bottom: 16px;
  }
}
