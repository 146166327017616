.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid #ebebeb;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }
  .btnClose {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #f5f5f5;
    }
    .closeIcon {
      width: 18px;
      height: 18px;
    }
  }
}

.content {
  padding: 20px;
  .row {
    display: flex;
    .col {
      flex: 1;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .text {
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 0;
    flex: 1;
    cursor: pointer;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  border-top: 1px solid #ebebeb;
}

.subTitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #242424;
  margin-bottom: 8px;
}
