.title {
  font-size: 32px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin-bottom: 24px;
}

.content {
  padding: 20px;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  .row {
    display: flex;
    .col {
      flex: 1;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .text {
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 0;
    flex: 1;
    cursor: pointer;
  }
}

.actions {
  padding: 0 20px;
}
