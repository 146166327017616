*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: 'Nunito Sans', sans-serif !important;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  text-align: left;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: 'Nunito Sans', sans-serif !important;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 100%;
  min-width: 350px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #D0B674;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #D0B674;
  color: #242424;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
  border-radius: 4px;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

/*
* react-circular-progressbar styles
* All of the styles in this file are configurable!
*/

.CircularProgressbar {
/*
 * This fixes an issue where the CircularProgressbar svg has
 * 0 width inside a "display: flex" container, and thus not visible.
 */
width: 100%;
/*
 * This fixes a centering issue with CircularProgressbarWithChildren:
 * https://github.com/kevinsqi/react-circular-progressbar/issues/94
 */
vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
stroke: #3e98c7;
stroke-linecap: round;
-webkit-transition: stroke-dashoffset 0.5s ease 0s;
transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
stroke: #d6d6d6;
/* Used when trail is not full diameter, i.e. when props.circleRatio is set */
stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
fill: #3e98c7;
font-size: 20px;
dominant-baseline: middle;
text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
fill: #d6d6d6;
}

/*
* Sample background styles. Use these with e.g.:
*
*   <CircularProgressbar
*     className="CircularProgressbar-inverted"
*     background
*     percentage={50}
*   />
*/
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
stroke: transparent;
}

.Loading__loading___1m_fZ {
pointer-events: none;
transition: 400ms linear all;
}

.Loading__bar___21yOt {
position: fixed;
top: 0;
left: 0;
z-index: 10002;
display: none;
width: 100%;
height: 2px;
background: #29d;
border-radius: 0 1px 1px 0;
transition: width 350ms;
}

.Loading__peg___3Y_28 {
position: absolute;
top: 0;
right: 0;
width: 70px;
height: 2px;
border-radius: 50%;
opacity: .45;
box-shadow: #29d 1px 0 6px 1px;
}

.Loading__spinner___11Pm4 {
position: fixed;
top: 5px;
left: 5px;
z-index: 10002;
pointer-events: none;
transition: 350ms linear all;
}

.Loading__icon___3OOyu {
width: 14px;
height: 14px;
border: solid #29d;
border-width: 0 2px 2px 0;
border-radius: 50%;
-webkit-animation: Loading__loading-bar-spinner___1hKY9 400ms linear infinite;
        animation: Loading__loading-bar-spinner___1hKY9 400ms linear infinite;
}

@-webkit-keyframes Loading__loading-bar-spinner___1hKY9 {
0% {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

100% {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
}

@keyframes Loading__loading-bar-spinner___1hKY9 {
0% {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

100% {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
}


.btn {
  height: 45px;
  padding: 0 30px;
  border-radius: 4px;
  border: solid 1px rgba(9,38,49,0.3) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #092631;
  cursor: pointer;
  background-color: #ffffff;
  min-width: 80px;
  margin-bottom: 0;
  transition-property: background-color,border-color,box-shadow;
  transition-duration: 85ms;
  transition-timing-function: ease;
}

.btn[data-style=contract] {
padding: 0 30px;
width: auto;
}


.btn[data-style=contract]:hover, .btn[data-style=contract][data-loading]  {
padding: 0 30px !important;
width: auto;
}


.btnSmall {
  height: 40px;
  padding: 0 20px;
  font-size: 12px;
}

.btnTiny {
  height: 32px;
  padding: 0 20px;
  font-size: 12px;
}

.btnLarge {
  height: 50px;
  padding: 0 50px;
  font-size: 16px;
}

@media (max-width: 767px) {
  .btnLarge {
    padding: 0 20px;
  }
  
}

.btnRed {
  border: 0 !important;
  background-color: #c91e33 !important;
  color: #ffffff !important;
}

.btnRed:hover {
  background-color: #b91e33 !important;
}

.uppercase {
  text-transform: uppercase;
}

.btnBlue {
  border: 0;
  color: #ffffff !important;
  transition: all 0.3s ease-in-out 0s;
  position: relative;
  color: #fff;
  background-color: #3f6ad8 !important;
  border-color: #3f6ad8 !important;
}

.btnBlue:hover {
  background-color: #5f6ad8 !important;
}

.btnMain {
  border: 0;
  color: #17204D !important;
  transition: all 0.3s ease-in-out 0s;
  position: relative;
  background-color: transparent;
  background: linear-gradient(31.65deg, #DEB856 6.17%, #FDE29B 93.12%);
  box-shadow: 0px 1px 30px rgba(9, 30, 66, 0.05), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: 0 !important;
}

.btnMain:hover {
  background-color: #9A844C !important;
}

.btnSecond {
  border: 1px solid #CFB675 !important;
  color: #CFB675 !important;
  transition: all 0.3s ease-in-out 0s;
  position: relative;
  background-color: #ffffff !important;
}

.btnSecond:hover {
  background-color: #f8f8f8 !important;
}


.w100 {
width: 100%;
}

.mr20 {
  margin-right: 20px;
}

.mr10 {
  margin-right: 10px;
}

.mr8 {
margin-right: 8px;
}

.ml20 {
margin-left: 20px;
}

.mr15 {
margin-right: 15px;
}

@media (max-width: 767px) {
.fixedActions {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 1px;
  margin-bottom: 0 !important;
  z-index: 99;
}
.fixedActions div {
  /* width: 100%; */
}
.btnFixedRight {
  width: calc(50vw - 1px) !important;
}
.btnFixedLeft {
  width: calc(50vw - 1px);
  margin-right: 0;
  border: 0;
  border-top: 1px solid #eeeeee;
  background-color: #ffffff;
  z-index: 9999;
}
.btnFixedFull {
  width: 100% !important;
}

.modal-dialog {
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;

}

.modal-content {
  height: 100%;
  overflow-y: auto;
}
}


/* DayPicker styles */

.DayPicker {
display: inline-block;
font-size: 1rem;
}

.DayPicker-wrapper {
position: relative;

flex-direction: row;
padding-bottom: 1em;

-webkit-user-select: none;

   -moz-user-select: none;

    -ms-user-select: none;

        user-select: none;
}

.DayPicker-Months {
display: flex;
flex-wrap: wrap;
justify-content: center;
}

.DayPicker-Month {
display: table;
margin: 0 1em;
margin-top: 1em;
border-spacing: 0;
border-collapse: collapse;

-webkit-user-select: none;

   -moz-user-select: none;

    -ms-user-select: none;

        user-select: none;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
position: absolute;
top: 1em;
right: 1.5em;
left: auto;

display: inline-block;
margin-top: 2px;
width: 1.25em;
height: 1.25em;
background-position: center;
background-size: 50%;
background-repeat: no-repeat;
color: #8B9898;
cursor: pointer;
}

.DayPicker-NavButton:hover {
opacity: 0.8;
}

.DayPicker-NavButton--prev {
margin-right: 1.5em;
background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next {
background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
display: none;
}

.DayPicker-Caption {
display: table-caption;
margin-bottom: 0.5em;
padding: 0 0.5em;
text-align: left;
}

.DayPicker-Caption > div {
font-weight: 500;
font-size: 1.15em;
}

.DayPicker-Weekdays {
display: table-header-group;
margin-top: 1em;
}

.DayPicker-WeekdaysRow {
display: table-row;
}

.DayPicker-Weekday {
display: table-cell;
padding: 0.5em;
color: #8B9898;
text-align: center;
font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
border-bottom: none;
text-decoration: none;
}

.DayPicker-Body {
display: table-row-group;
}

.DayPicker-Week {
display: table-row;
}

.DayPicker-Day {
display: table-cell;
padding: 0.5em;
border-radius: 50%;
vertical-align: middle;
text-align: center;
cursor: pointer;
}

.DayPicker-WeekNumber {
display: table-cell;
padding: 0.5em;
min-width: 1em;
border-right: 1px solid #EAECEC;
color: #8B9898;
vertical-align: middle;
text-align: right;
font-size: 0.75em;
cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
cursor: default;
}

.DayPicker-Footer {
padding-top: 0.5em;
}

.DayPicker-TodayButton {
border: none;
background-color: transparent;
background-image: none;
box-shadow: none;
color: #4A90E2;
font-size: 0.875em;
cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
color: #D0021B;
font-weight: 700;
}

.DayPicker-Day--outside {
color: #8B9898;
cursor: default;
}

.DayPicker-Day--disabled {
color: #DCE0E0;
cursor: default;
background-color: #f8f8f8;
color: #999999;
pointer-events: none;
/* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
position: relative;

background-color: #4A90E2;
color: #F0F8FF;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
background-color: #51A0FA;
}

.DayPicker:not(.DayPicker--interactionDisabled)
.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
background-color: #F0F8FF;
}

/* DayPickerInput */

.DayPickerInput {
display: inline-block;
}

.DayPickerInput-OverlayWrapper {
position: relative;
}

.DayPickerInput-Overlay {
position: absolute;
left: 0;
z-index: 1;

background: white;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.overflow-none > .carousel > .carousel-inner {
overflow: unset;
}

.custom-modal {
padding: 0 !important;
}

.custom-modal > .modal-dialog {
max-width: 100%;
margin: 0;
}

.custom-modal > .modal-dialog > .modal-content {
width: 100vw;
height: calc(100vh - 70px);
left: 0;
top: 0;
}

@media (max-width: 767px) {
.custom-modal > .modal-dialog > .modal-content {
  width: 100vw;
  height: calc(100vh);
  left: 0;
  top: 0;
}
}

.custom-modal2 {
padding: 0 !important;
}

.custom-modal2 > .modal-dialog {
max-width: 100%;
margin: 0;
}

.custom-modal2 > .modal-dialog > .modal-content {
width: 100vw;
height: calc(100vh);
left: 0;
top: 0;
}

.desktop-boundery {
max-width: 1280px;
margin: 0 auto;
}

.input-range__slider {
  background: #c91e33;
  border: 1px solid #c91e33;
}

.input-range__track--active {
  background: #c91e33;
}

.modal-md {
  max-width: 660px;
}

.mt30 {
  margin-top: 30px;
}

.adminContainer * {
  font-family: 'Nunito Sans', sans-serif !important;
}
