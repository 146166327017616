.campaignName {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 0;
  @media (max-width: 767px) {
    font-size: 24px;
  }
}

.rowBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.row {
  display: flex;
  align-items: center;
  .btnBack {
    width: 42px;
    height: 42px;
    border-radius: 8;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &:hover {
      background-color: #C1C4D0;
    }
    .arrowLeft {
      width: 18px;
    }
    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
      .arrowLeft {
        width: 18px;
      }
    }
  }
  margin-bottom: 36px;
}

.subtitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.container {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}


.empty {
  width: 100%;
  border: 1px dashed #ebebeb;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px 60px;
  flex-direction: column;
  border-radius: 20px;
  margin: 40px auto;
  .title {
    font-style: 32px;
    font-weight: bold;
    color: #333333;
  }
}
