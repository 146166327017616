.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../../images/auth-background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  min-height: 100vh;
  .logo {
    width: 257px;
    margin-bottom: 10px;
  }
  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 54px;
    span {
      color: #C8AA5D;
    }
  }
  .content {
    background: #FFFFFF;
    box-shadow: 0px 23px 50px rgba(10, 31, 67, 0.2), 0px 12px 7px rgba(10, 31, 67, 0.05);
    border-radius: 8px;
    padding: 40px 60px;
    max-width: 420px;
    width: 100%;
    margin: 0 auto;
    .row {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 40px;
      margin-bottom: 74px;
      .divider {
        width: 1px;
        height: 19px;
        background-color: #6e7580;
        margin: 0 20px;
      }
    }
    .becomePartner {
      margin-top: 40px;
      margin-bottom: 74px;
      text-align: right;
      
    }

    .textLink {
      font-size: 16px;
      font-weight: 500;
      text-decoration: underline;
      color: #000000 !important;
    }
  }

  @media (max-width: 767px) {
    .content {
      width: 100%;
      padding: 20px;
      .becomePartner {
        margin-bottom: 40px;
      }
    }
  }
}
