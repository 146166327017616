.title {
  font-weight: bold;
  font-size: 32px;
  line-height: 43px;
  color: #242424;
}

.group {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 30px;
  .head {
    padding: 21px 32px;
    background: #8994A9;
    border-radius: 4px 4px 0px 0px;
    .headTitle {
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      color: #FFFFFF;
      margin-bottom: 0;
    }
    .headDescription {
      font-size: 14px;
      line-height: 19px;
      color: #FFFFFF;
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
  .groupBody {
    padding: 22px 32px;
  }

  .row {
    display: flex;
    // align-items: center;
    .box {
      flex: 1;
      margin-right: 24px;
      background: #FFFFFF;
      border: 1px solid #BEC2CA;
      border-radius: 8px;
      &:last-child {
        margin-right: 0;
      }
      padding: 0 20px;
    }
    .boxHead {
      padding: 12px 0 10px 0;
      border-bottom: 1px solid #BEC2CA;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      color: #2D4053;
      margin-bottom: 20px;
    }
    .mb20 {
      margin-bottom: 20px;
    }
    .label {
      flex: 1;
      padding-right: 10px;
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 0;
      color: #767676;
    }
    .col {
      flex: 1;
      padding-left: 10px;
      display: flex;
    }
    .field {
      display: flex;
      align-items: center;
      flex: 1;
      
      .col {
        flex: 1;
        padding-left: 0;
        width: 100%;
      }
      .divider {
        width: 8px;
        height: 2px;
        background-color: #979797;
        margin: 0 10px;
      }
    }
  }
  
}

.bottom {
  padding: 0 32px;
  max-width: 632px;
  .note {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #585858;
    margin-bottom: 16px;
  }
  .text {
    font-size: 14px;
    line-height: 19px;
    color: #585858;
    margin-bottom: 32px;
  }

}
