.nftType {
  background: #ffffff;
  border: 1px solid #DFE0E6;
  border-radius: 18px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 16px;
  color: #5E6484;
  padding: 0 10px;
  margin-bottom: 16px;
}

.container {
  width: 100%;
  border-radius: 6px;
  background-color: #f8f8f8;
  overflow: hidden;
  .table {
    width: 100%;
  }
  .thead {
    background-color: #ebebeb;
    
    th {
      font-size: 14px;
      font-weight: 700;
      line-height: 1.27;
      padding: 10px 20px;
      vertical-align: top;
      min-width: 100px;
      color: #333333;
      cursor: pointer;
      position: relative;
      white-space: nowrap;
      text-align: right;
    }
  }

  .tbRow {
    border-bottom: 1px solid #ebebeb;
    td {
      font-size: 14px;
      line-height: 19px;
      padding: 20px;
      font-weight: normal;
      color: #767676;
      text-align: right;
    }
  
  }

  .left {
    text-align: left !important;
  }

  .total {
    td {
      font-size: 16px;
      line-height: 21px;
      padding: 20px;
      font-weight: bold;
      color: #FFD362;
      text-align: right;
    }
  
  }

}
