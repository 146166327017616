.input {
  box-shadow: none !important;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #BFC7CA;
  background-color: #FAFAFC !important;
  font-size: 16px;
  color: #585858;
  font-weight: 600;
  outline: 0;
  padding: 0 15px;
  width: 100%;
  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus {
    background-color: transparent !important;
  }
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #6e7580;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: #6e7580;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: #6e7580;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: #6e7580;
  }
}

.h50 {
  height: 50px;
}

.focus {
  border: 1.5px solid #EBDAAD !important;
  box-shadow: 0px 2px 12px rgba(255, 184, 0, 0.2) !important;
  background-color: #ffffff !important;
}

.fullBorder {
  border-radius: 4px;
  box-shadow: inset 0 1px 2px 0 rgba(102, 113, 123, 0.21) !important;
  padding: 0 16px;
  border: 1px solid #e2e5ed;
}

.floatingLabel {
  position: absolute;
  bottom: 8px;
  left: 0;
  color: #999999;
  pointer-events: none;
  // transition: all 0.5s ease-in-out;
  transition: .5s;
  font-size: 12px;
}

.inputContainer {
  position: relative;
  display: inline-flex;
  width: 100%;
  .calendarIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
  }
  .btnClear {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 36px;
    &:hover {
      background-color: #f5f5f5;
    }
    .closeIcon {
      width: 16px;
      height: 16px;
    }
  }
}

.labelError {
  font-size: 12px;
  color: #CF1E1E !important;
}

.errorMessage {
  font-size: 12px;
  color: #CF1E1E !important;
  margin-left: 6px
}

.errorField {
  border-color: #ff6565
}

.dateTimePicker {
  position: absolute;
  z-index: 99;
  top: 50px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
}

.btnWrapper {
  padding: 10px;
  background-color: #ffffff;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  .btnCancel {
    font-size: 14px;
    color: #888888;
    cursor: pointer;
    font-weight: bold;
  }

  .btnOk {
    font-size: 14px;
    color: #007aff;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.inputContainer {
  .disabled {
    pointer-events: none;
  }
  
}

.containerDisabled, .disabled {
  background-color: #efefef !important;
}


