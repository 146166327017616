.container {
  padding: 0 20px;
  .mt20 {
    margin-top: 20px;
  }
  .btnBack {
    width: 30px;
    height: 30px;
    border-radius: 8;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &:hover {
      background-color: #C1C4D0;
    }
    .arrowLeft {
      width: 16px;
    }
    
  }
  .mb40 {
    margin-bottom: 40px;
  }
  .content {
    max-width: 760px;
    width: 100%;
    margin: 0 auto;
    .title {
      font-size: 32px;
      line-height: 43px;
      color: #242424;
      font-weight: bold;
      margin-bottom: 0;
    }
    .subTitle {
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      color: #242424;
    }
    .mt40 {
      margin-top: 40px;
    }
    .text {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.6);
    }
    .row {
      display: flex;
      align-items: flex-start;
    }
    .rowCenter {
      align-items: center;
    }
    .left {
      flex: 1;
      margin-right: 20px;
      background: #FFFFFF;
      box-shadow: 0px 3px 25px rgba(9, 30, 66, 0.09), 0px 2px 5px rgba(9, 30, 66, 0.05);
      border-radius: 8px;
      padding: 40px 30px;
    }
    .right {
      width: 240px;
      
      .preview {
        background: #FFFFFF;
        border: 1px solid #C1C4D0;
        box-shadow: 0px 6px 20px rgba(32, 44, 55, 0.15);
        border-radius: 8px;
        padding: 16px 12px 20px 12px;
      }
      .rowBetween {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .rare {
        padding: 2px 10px;
        border-radius: 18px;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        border-width: 1px;
        border-style: solid;
      }
      .numberEditions {
        background: #F4F5F7;
        border: 1px solid #DFE0E6;
        border-radius: 18px;
        display: flex;
        align-items: center;
        padding: 2px 10px;
        font-size: 12px;
        color: #5E6484;
      }
      .subTitle {
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        color: #242424;
        margin-bottom: 20px;
        margin-top: 40px;
      }
      .imageWrapper {
        margin: 16px 0;
        background: #FFFFFF;
        box-shadow: 0px 10px 10px -10px rgba(0, 39, 85, 0.5), 0px 6px 20px rgba(0, 39, 85, 0.35);
        border-radius: 8px;
        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
        }
      }
      .name {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #42496E;
        margin-bottom: 4px;
      }
      .row {
        display: flex;
        align-items: center;
      }
      .avatar {
        width: 16px;
        height: 16px;
        object-fit: cover;
        margin-right: 4px;
        border-radius: 50%;
      }
      .owner {
        font-size: 12px;
        line-height: 16px;
        color: #7A7F9A;
        margin-bottom: 0;
      }
      .divider {
        width: 100%;
        height: 1px;
        background-color: #E6E6E6;
        margin: 12px 0;
      }
      .label {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0;
        color: #7A7F9A;
      }
      .price {
        margin-bottom: 0;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #C9A138;
      }
      
    }
    .col {
      flex: 1;
      margin-right: 20px;
      .label {
        margin-top: 10px;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    @media (max-width: 767px) {
      .title {
        font-size: 24px;
      }
      .subTitle {
        font-size: 16px;
      }
      .left {
        width: 100%;
        margin-right: 0;
      }
      .right {
        width: 100%;
        margin-top: 20px;
      }
      .rowContent {
        flex-direction: column;
      }
      .col {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.label {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #242424;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.mb25 {
  margin-bottom: 25px;
}

