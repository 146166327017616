.notification {
  padding: 20px;
  display: flex;
  justify-content: center;
  .noti {
    position: relative;
    pointer-events: auto;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px;
    min-width: 300px;
    max-width: 500px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    -moz-box-shadow: 0 0 12px #999;
    -webkit-box-shadow: 0 0 12px #999;
    box-shadow: 0 0 12px #999;
    color: #fff;
    opacity: .9;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    filter: alpha(opacity=80);
    text-align: left;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    transform: translateX(-50%);
    .icon {
      height: 30px;
      margin-right: 20px;
    }
  }
  .error {
    background-color: #bd362f;
  }

  .success {
    background-color: #51a351;
  }

  @media (max-width: 767px) {
    .noti {
      width: 300px;
    }
  }
}
