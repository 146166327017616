.btnRemove {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  margin: 5px;
  color: rgb(51, 51, 51);
  background-color: rgb(255, 255, 255);
  img {
    width: 20px;
  }
}

.btnAccept {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid rgb(76, 174, 76);
  border-radius: 4px;
  margin: 5px;
  color: rgb(255, 255, 255);
  background-color: rgb(92, 184, 92);
  img {
    width: 20px;
  }
}
.container {
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  .label {
    margin-right: 100px;
  }
}

.avatarWrapper {
  display: block;
  background-size: contain;
}

.wrapper {
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.progress {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 204px;
}

.btnDelete {
  width: 40px;
  height: 40px;
  background-color: rgba(0,0,0,0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 19px;
  bottom: 19px;
  cursor: pointer;
  z-index: 9;
  .deleteIcon {
    font-size: 16px;
    color: #ffffff;
    width: 14px;
  }
}

.container {
  width: 100%;
  height: 100%;
}

