.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid #ebebeb;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }
  .btnClose {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #f5f5f5;
    }
    .closeIcon {
      width: 18px;
      height: 18px;
    }
  }
}

.content {
  padding: 20px;
  .row {
    display: flex;
    align-items: center;
    .imageWrapper {
      flex: 1;
      background: #F9F9F9;
      padding: 30px;
      text-align: center;
      .img {
        height: 100%;
        width: 490px;
        height: auto;
        object-fit: cover;
      }

    }
    .info {
      flex: 1;
      padding: 0 50px;
    }

    .itemName {
      font-weight: bold;
      font-size: 32px;
      line-height: 44px;
      color: #242424;
      margin-bottom: 20px;
    }

    .text {
      font-size: 14px;
      line-height: 19px;
      color: #767676;
      margin-bottom: 16px;
      b {
        color: #000000;
      }
      .campaignName {
        color: #3D92E1;
      }
    }

    .label {
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      color: #242424;
      margin-bottom: 10px;
      margin-top: 24px;
    }
    .descriptionText {
      font-size: 14px;
      line-height: 28px;
      color: #585858;
    }
  }

  .text {
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 0;
    flex: 1;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    .row {
      flex-direction: column;
      .info {
        padding: 0 20px;
      }
    }
    
    
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  .btnBurn {
    background: #EBECF0;
    border-radius: 4px;
    height: 40px;
    border: 0;
    outline: 0;
    padding: 0 20px;
    font-weight: 600;
    font-size: 14px;
    color: #FF5630;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }
  .burnIcon {
    width: 16px;
    margin-right: 10px;
  }
}

.nftType {
  background: #ffffff;
  border: 1px solid #DFE0E6;
  border-radius: 18px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 16px;
  color: #5E6484;
  padding: 0 10px;
}
