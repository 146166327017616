.row {
  display: flex;
  align-items: flex-start;
  .btnBack {
    width: 42px;
    height: 42px;
    border-radius: 8;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &:hover {
      background-color: #C1C4D0;
    }
    .arrowLeft {
      width: 18px;
    }
    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
      .arrowLeft {
        width: 18px;
      }
    }
  }
 
  .left {
    flex: 1;
    margin-right: 20px;
  }
  .right {
    flex: 1;
    margin-left: 20px;
  }
  .col {
    flex: 1;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
  .about {
    flex: 1;
    margin-left: 30px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    
    .left {
      margin-right: 0;
      width: 100%;
    }
    .right {
      margin-left: 0;
      width: 100%;
    }
    .col {
      width: 100%;
      margin-right: 0;
    }
    .about {
      width: 100%;
      margin-left: 0;
      margin-top: 24px;
    }
  }
}

.label {
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;

  color: #000000;
  margin-bottom: 8px;
  .required {
    color: #ff6565;
    margin-left: 4px;
  }
}

.container {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.subTitle {
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 20px;
}

.m50 {
  margin-top: 50px;
}

.mb25 {
  margin-bottom: 25px;
}

.mt25 {
  margin-top: 25px;
}

.mb36 {
  margin-bottom: 36px;
  align-items: center;
}

.campaignName {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
}

.actions {
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
}

.empty {
  text-align: center;
  font-size: 15px;
  color: #666666;
  font-style: italic;;
}

.picture {
  width: 290px;
}
