.thumbImage {
  margin-top: 40px;
  margin-bottom: 25px;
  .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #242424;
    margin-bottom: 4px;
  }
  .description {
    font-size: 14px;
    line-height: 28px;
    color: #585858;
    margin-bottom: 10px;
  }

  .uploadThumbImage {
    padding: 26px 20px 24px 20px;
    border: 1px dashed #BDBDBD;
    border-radius: 4px;
    text-align: center;
    .upload {
      font-size: 12px;
      line-height: 16px;
      color: #585858;
      margin-top: 6px;
      margin-bottom: 0;
    }
  }

  .image {
    width: 142px;
    height: 90px;
    object-fit: cover;
  }

  .wrapper {
    position: relative;
    width: 142px;
    .btnRemoveImage {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -12px;
      right: -12px;
      z-index: 99;
      background-color: #f5f5f5;
      &:hover {
        background-color: #f5f5f5;
      }
      .closeIcon {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.imageWrapper {
  position: relative;
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
